import { IconSitemap } from "@tabler/icons-react";
import { Button } from "components/button";

import { useTranslation } from "react-i18next";

import { Dialog } from "components/dialog/Dialog";
import { DialogTrigger } from "components/dialog/DialogTrigger";
import { DialogContent } from "components/dialog/DialogContent";
import { DialogTitle } from "components/dialog/DialogTitle";
import { DialogBody } from "components/dialog/DialogBody";
import { DialogActions } from "components/dialog/DialogActions";
import { useState } from "react";
import { Input } from "components/input/Input";
import { Radio } from "components/checkbox/Radio";
import { Text } from "../../../../common/components/atoms/typography/Text";
import { useSetRawSchemaPrefixDlmMutation } from "src/common/redux/api/exopenApi";
import { Chip } from "components/chip/Chip";
import {
	showErrorNotification,
	showSuccessNotification,
} from "components/notifications/events";

interface Props {
	companyDomainId: string;
	currentRawPrefix?: string | undefined;
	disabled?: boolean;
	onSetRawPrefixSuccess?: () => void;
}

enum RadioOptions {
	CurrentPrefix = 0,
	EmptyString = 1,
	Other = 2,
}

export const ChangeDlmRawPrefixModalButton = ({
	companyDomainId,
	currentRawPrefix,
	disabled,
	onSetRawPrefixSuccess,
}: Props) => {
	const { t } = useTranslation();

	const [open, setOpen] = useState(false);
	const [selectedRadio, setSelectedRadio] = useState<RadioOptions>();

	const determinePrefix = (prefix: any) => {
		if (typeof prefix === "string") {
			prefix === ""
				? setSelectedRadio(RadioOptions.EmptyString)
				: setSelectedRadio(RadioOptions.CurrentPrefix);
			return prefix;
		}
		return undefined;
	};

	const [rawPrefix, setRawPrefix] = useState(() =>
		determinePrefix(currentRawPrefix),
	);

	const [setRawPrefixDlm, { isLoading: isSettingRawPrefix }] =
		useSetRawSchemaPrefixDlmMutation();

	return (
		<Dialog open={open}>
			<DialogTrigger asChild>
				<Button
					ariaLabel={t("Change raw prefix")}
					icon={<IconSitemap />}
					disabled={disabled ?? false}
					onClick={() => setOpen(true)}
					variant="secondary"
				>
					{t("Change raw prefix")}
				</Button>
			</DialogTrigger>
			<DialogContent size="md">
				<DialogTitle>{t("Choose Desired Configuration")}</DialogTitle>
				<DialogBody>
					<label className="mb-5 flex items-center gap-2">
						<div className="shrink-0">
							<Radio
								checked={selectedRadio === RadioOptions.CurrentPrefix}
								onChange={() => {
									const value =
										currentRawPrefix === "" ? "Dlm" : currentRawPrefix;
									setRawPrefix(value);
									setSelectedRadio(RadioOptions.CurrentPrefix);
								}}
							/>
						</div>
						<Text size="sm" color="text-grey-700" weight="medium">
							{currentRawPrefix === "" ? "Dlm" : currentRawPrefix}
						</Text>
						{currentRawPrefix && (
							<Chip size="small" color="primary">
								{t("CURRENT PREFIX")}
							</Chip>
						)}
					</label>
					<label className="mb-5 flex items-center gap-2">
						<div className="shrink-0">
							<Radio
								checked={selectedRadio === RadioOptions.EmptyString}
								onChange={() => {
									setRawPrefix("");
									setSelectedRadio(RadioOptions.EmptyString);
								}}
							/>
						</div>
						<Text size="sm" color="text-grey-700" weight="medium">
							Empty String
						</Text>
						{currentRawPrefix === "" && (
							<Chip size="small" color="primary">
								{t("CURRENT PREFIX")}
							</Chip>
						)}
					</label>
					<label className="mb-5 flex items-center gap-2">
						<div className="shrink-0">
							<Radio
								checked={selectedRadio === RadioOptions.Other}
								onChange={() => {
									setSelectedRadio(RadioOptions.Other);
								}}
							/>
						</div>
						<Text size="sm" color="text-grey-700" weight="medium">
							{t("Other")}
						</Text>
					</label>
					{selectedRadio === RadioOptions.Other && (
						<Input
							onChange={(event) => {
								if (event.target.value.length > 0)
									setRawPrefix(event.target.value);
							}}
						/>
					)}
				</DialogBody>
				<DialogActions
					withCancelButton
					onCancel={() => {
						setRawPrefix(determinePrefix(currentRawPrefix));
						setOpen(false);
					}}
				>
					<Button
						isLoading={isSettingRawPrefix}
						icon={<IconSitemap />}
						disabled={rawPrefix === currentRawPrefix}
						onClick={async () => {
							if (typeof rawPrefix === "string") {
								const result = await setRawPrefixDlm({
									companyDomainId,
									schemaName: rawPrefix,
								});
								if ("error" in result) {
									showErrorNotification({
										message: t("Something failed..."),
									});
								} else {
									showSuccessNotification({
										message: t("Changes successfully implemented"),
									});

									if (onSetRawPrefixSuccess) onSetRawPrefixSuccess();

									rawPrefix === ""
										? setSelectedRadio(RadioOptions.EmptyString)
										: setSelectedRadio(RadioOptions.CurrentPrefix);

									setOpen(false);
								}
							}
						}}
					>
						{t("Change raw prefix")}
					</Button>
				</DialogActions>
			</DialogContent>
		</Dialog>
	);
};
