import { useId, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCurrentCompanyDomain } from "../../../common/company-domain/useCurrentCompanyDomain.js";
import { Button } from "../../../common/components/atoms/button/Button.js";
import {
	showErrorNotification,
	showSuccessNotification,
} from "../../../common/components/atoms/notifications/events.js";
import { useLegalEntitiesOrThrow } from "../../../common/legal-entities/useLegalEntities.js";
import {
	useCreateExopenDataModelApiPumpMutation,
	useCreateExopenForecastPumpMutation,
	useCreateExopenPlanningPumpMutation,
} from "../../../common/redux/api/exopenApi.js";
import { invariant } from "../../../common/utils/invariant.js";
import type { LegalEntity } from "src/common/service/nexus/endpoints/companyDomain/types.js";
import { Select } from "../../../common/components/atoms/select/Select.js";
import { Label } from "../../../common/components/atoms/label/Label.js";
import { Dialog } from "components/dialog/Dialog.js";
import { DialogContent } from "components/dialog/DialogContent.js";
import { DialogTitle } from "components/dialog/DialogTitle.js";
import { DialogBody } from "components/dialog/DialogBody.js";
import { DialogActions } from "components/dialog/DialogActions.js";

type Props = {
	isOpen: boolean;
	onClose: () => void;
};

const PUMP_TYPES = {
	exopenDataModel: "Exopen Data Model",
	exopenForecast: "Exopen Forecast",
	exopenPlanning: "Exopen Planning",
};

type PumpType = keyof typeof PUMP_TYPES;

export const CreatePumpModal = ({ isOpen, onClose }: Props) => {
	const { companyDomain } = useCurrentCompanyDomain();

	const { legalEntities } = useLegalEntitiesOrThrow();

	const [
		createExopenDataModelPump,
		{ isLoading: isLoadingExopenDataModelPump },
	] = useCreateExopenDataModelApiPumpMutation();
	const [createExopenForecastPump, { isLoading: isLoadingExopenForecastPump }] =
		useCreateExopenForecastPumpMutation();
	const [createExopenPlanningPump, { isLoading: isLoadingExopenPlanningPump }] =
		useCreateExopenPlanningPumpMutation();

	const [pumpType, setPumpType] = useState<PumpType>("exopenDataModel");
	const [selectedLegalEntity, setSelectedLegalEntity] =
		useState<LegalEntity | null>(null);

	const isCreating =
		isLoadingExopenDataModelPump ||
		isLoadingExopenForecastPump ||
		isLoadingExopenPlanningPump;

	const { t } = useTranslation();

	const getMutation = (
		pumpType: PumpType,
		companyDomainId: string,
		leMasterKey?: string,
	) => {
		switch (pumpType) {
			case "exopenDataModel":
				return createExopenDataModelPump({ companyDomainId });
			case "exopenForecast":
				invariant(leMasterKey);
				return createExopenForecastPump({ companyDomainId, leMasterKey });
			case "exopenPlanning":
				invariant(leMasterKey);
				return createExopenPlanningPump({ companyDomainId, leMasterKey });
		}
	};

	const isValid = pumpType === "exopenDataModel" || !!selectedLegalEntity;
	const legalEntityLabelId = useId();

	return (
		<Dialog open={isOpen} onClose={onClose}>
			<DialogContent>
				<DialogTitle>{t("Create Pump")}</DialogTitle>
				<DialogBody>
					<Select
						ariaLabel={t("Pump type")}
						placeholder={t("Select pump type")}
						options={Object.entries(PUMP_TYPES).map(([key, value]) => ({
							label: value,
							value: key,
						}))}
						value={pumpType}
						onChange={(value) => {
							if (value) {
								setPumpType(value as PumpType);
							}
						}}
					/>
					{pumpType !== "exopenDataModel" && (
						<div className="mt-4">
							<Label id={legalEntityLabelId}>{t("Legal entity")}</Label>
							<Select
								placeholder={t("Select legal entity")}
								withFilter
								aria-labelledby={legalEntityLabelId}
								value={selectedLegalEntity?.id ?? null}
								onChange={(legalEntityId) => {
									if (legalEntityId) {
										const matchingLegalEntity = legalEntities.find(
											(legalEntity) => legalEntity.id === legalEntityId,
										);

										if (matchingLegalEntity) {
											setSelectedLegalEntity(matchingLegalEntity);
										}
									} else {
										setSelectedLegalEntity(null);
									}
								}}
								options={legalEntities.map((legalEntity) => {
									return {
										value: legalEntity.id,
										label: legalEntity.name,
									};
								})}
							/>
						</div>
					)}
				</DialogBody>
				<DialogActions withCancelButton>
					<Button
						isLoading={isCreating}
						disabled={!isValid}
						onClick={async () => {
							const result = await getMutation(
								pumpType,
								companyDomain.id,
								selectedLegalEntity?.legalEntityMasterKey,
							);

							if ("error" in result) {
								showErrorNotification({
									message: t("Failed to create pump"),
								});
							} else {
								showSuccessNotification({
									message: t("Pump created"),
								});
								onClose();
							}
						}}
					>
						{t("Create")}
					</Button>
				</DialogActions>
			</DialogContent>
		</Dialog>
	);
};
