import { IconPlus } from "@tabler/icons-react";
import { Button } from "components/button";

import { useTranslation } from "react-i18next";

import { Dialog } from "components/dialog/Dialog";
import { DialogTrigger } from "components/dialog/DialogTrigger";
import { DialogContent } from "components/dialog/DialogContent";
import { DialogTitle } from "components/dialog/DialogTitle";
import { DialogBody } from "components/dialog/DialogBody";
import { DialogActions } from "components/dialog/DialogActions";
import { useSetCompanyDefaultDestinationDlmMutation } from "src/common/redux/api/exopenApi";
import { useState } from "react";
import {
	showErrorNotification,
	showSuccessNotification,
} from "components/notifications/events";
import { SwitchField } from "components/checkbox/SwitchField";
import { Chip } from "components/chip/Chip";
import { DlmMarts } from "./dlmMarts";

interface Props {
	companyDomainId: string;
	defaultFinanceMart?: boolean | undefined;
	defaultRawMart?: boolean | undefined;
	onAddDestinationSuccess?: (() => void) | undefined;
}

export const AddDlmDestinationModalButton = ({
	companyDomainId,
	defaultFinanceMart,
	defaultRawMart,
	onAddDestinationSuccess,
}: Props) => {
	const { t } = useTranslation();

	const [open, setOpen] = useState(false);
	const [financeMartEnabled, setFinanceMartEnabled] = useState(
		defaultFinanceMart ?? true,
	);
	const [rawMartEnabled, setRawMartEnabled] = useState(defaultRawMart ?? false);

	const [setCompanyDefaultDestinationDlm, { isLoading: isAddingDestination }] =
		useSetCompanyDefaultDestinationDlmMutation();

	const defaultMartConfigExists =
		defaultFinanceMart !== undefined && defaultRawMart !== undefined;

	return (
		<Dialog open={open}>
			<DialogTrigger asChild>
				<Button
					ariaLabel={
						defaultMartConfigExists
							? t("Update destination")
							: t("Add destination")
					}
					icon={<IconPlus />}
					onClick={() => setOpen(true)}
				>
					{defaultMartConfigExists
						? t("Update destination")
						: t("Add destination")}
				</Button>
			</DialogTrigger>
			<DialogContent size="md">
				<DialogTitle>{t("Choose Desired Configuration")}</DialogTitle>
				<DialogBody>
					{Object.values(DlmMarts).map((mart) => {
						const isActiveCurrentDefault =
							mart == DlmMarts.Finance ? defaultFinanceMart : defaultRawMart;

						return (
							<div className="flex pb-2 pt-2" key={mart}>
								<SwitchField
									label={mart}
									checked={
										mart == DlmMarts.Finance
											? financeMartEnabled
											: rawMartEnabled
									}
									onChange={() =>
										mart == DlmMarts.Finance
											? setFinanceMartEnabled(!financeMartEnabled)
											: setRawMartEnabled(!rawMartEnabled)
									}
									labelChip={
										defaultMartConfigExists ? (
											<Chip
												size="small"
												color={isActiveCurrentDefault ? "primary" : "error"}
												className="ml-2"
											>
												{isActiveCurrentDefault
													? t("Currently enabled")
													: t("Currently disabled")}
											</Chip>
										) : undefined
									}
									supportingText={t(
										"Will activate the {{mart}} mart for all legal entities in the company domain.",
										{
											mart,
										},
									)}
								/>
							</div>
						);
					})}
				</DialogBody>
				<DialogActions
					withCancelButton
					onCancel={() => {
						setOpen(false);
						setFinanceMartEnabled(defaultFinanceMart ?? true);
						setRawMartEnabled(defaultRawMart ?? false);
					}}
				>
					<Button
						isLoading={isAddingDestination}
						icon={<IconPlus />}
						disabled={
							defaultFinanceMart === financeMartEnabled &&
							defaultRawMart === rawMartEnabled
						}
						onClick={async () => {
							const result = await setCompanyDefaultDestinationDlm({
								companyDomainId,
								financeMartEnabled,
								rawMartEnabled,
							});
							if ("error" in result) {
								showErrorNotification({
									message: t("Something failed..."),
								});
							} else {
								showSuccessNotification({
									message: t("Changes successfully implemented"),
								});
								if (onAddDestinationSuccess) onAddDestinationSuccess();
								setOpen(false);
							}
						}}
					>
						{defaultMartConfigExists
							? t("Update destination")
							: t("Add destination")}
					</Button>
				</DialogActions>
			</DialogContent>
		</Dialog>
	);
};
