import { IconDatabase } from "@tabler/icons-react";
import { Button } from "components/button";

import { useTranslation } from "react-i18next";

import { Dialog } from "components/dialog/Dialog";
import { DialogTrigger } from "components/dialog/DialogTrigger";
import { DialogContent } from "components/dialog/DialogContent";
import { DialogTitle } from "components/dialog/DialogTitle";
import { DialogBody } from "components/dialog/DialogBody";
import { Radio } from "components/checkbox/Radio";
import { Text } from "../../../../common/components/atoms/typography/Text";
import { DialogActions } from "components/dialog/DialogActions";
import { useState } from "react";
import {
	useResetDatabaseDlmFinanceMutation,
	useResetDatabaseDlmRawMutation,
} from "src/common/redux/api/exopenApi";
import { Select } from "components/select/Select";
import {
	showErrorNotification,
	showSuccessNotification,
} from "components/notifications/events";
import { DlmMarts } from "./dlmMarts";

interface Props {
	companyDomainId: string;
	availableRawSystems: { label: string; value: string }[];
	disabled?: boolean;
}

export const ResetDatabaseModalButton = ({
	companyDomainId,
	availableRawSystems,
	disabled,
}: Props) => {
	const { t } = useTranslation();

	const [open, setOpen] = useState(false);
	const [chosenMart, setChosenMart] = useState<DlmMarts | null>(null);
	const [chosenSystem, setChosenSystem] = useState<string | null>(null);

	const [resetFinanceDb, { isLoading: isLoadingFinance }] =
		useResetDatabaseDlmFinanceMutation();
	const [resetRawDb, { isLoading: isLoadingRaw }] =
		useResetDatabaseDlmRawMutation();

	return (
		<Dialog open={open}>
			<DialogTrigger asChild>
				<Button
					ariaLabel={t("Reset database")}
					icon={<IconDatabase />}
					variant="primary"
					disabled={disabled ?? false}
					onClick={() => setOpen(true)}
				>
					{t("Reset database")}
				</Button>
			</DialogTrigger>
			<DialogContent size="md">
				<DialogTitle>{t("Reset database")}</DialogTitle>
				<DialogBody>
					<label className="mb-5 flex items-center gap-2">
						<div className="shrink-0">
							<Radio
								checked={chosenMart === DlmMarts.Finance}
								onChange={() => {
									setChosenMart(DlmMarts.Finance);
									if (chosenSystem) setChosenSystem(null);
								}}
							/>
						</div>
						<div>
							<Text size="sm" color="text-grey-700" weight="medium">
								Finance
							</Text>
						</div>
					</label>
					<label className="mb-5 flex items-center gap-2">
						<div className="shrink-0">
							<Radio
								checked={chosenMart === DlmMarts.Raw}
								onChange={() => setChosenMart(DlmMarts.Raw)}
							/>
						</div>
						<div>
							<Text size="sm" color="text-grey-700" weight="medium">
								Raw
							</Text>
						</div>
					</label>
					{chosenMart === DlmMarts.Raw && (
						<Select
							aria-labelledby={companyDomainId}
							placeholder={t("Select system")}
							options={availableRawSystems}
							value={chosenSystem}
							onChange={(value) => {
								if (value) {
									setChosenSystem(value);
								}
							}}
							className="mb-4"
						/>
					)}
				</DialogBody>
				<DialogActions
					withCancelButton
					onCancel={() => {
						setChosenMart(null);
						setChosenSystem(null);
						setOpen(false);
					}}
				>
					<Button
						isLoading={isLoadingFinance || isLoadingRaw}
						disabled={
							!chosenMart ||
							isLoadingFinance ||
							isLoadingRaw ||
							(chosenMart === DlmMarts.Raw && !chosenSystem)
						}
						icon={<IconDatabase />}
						onClick={async () => {
							let promise;
							if (chosenMart === DlmMarts.Finance) {
								promise = resetFinanceDb({ companyDomainId });
							} else {
								promise = resetRawDb({
									companyDomainId,
									system: chosenSystem!,
								});
							}
							const result = await promise;
							if ("error" in result) {
								showErrorNotification({
									message: t("Something failed..."),
								});
							} else {
								showSuccessNotification({
									message: t("Database has been reset"),
								});
								setOpen(false);
							}
						}}
					>
						{t("Reset database")}
					</Button>
				</DialogActions>
			</DialogContent>
		</Dialog>
	);
};
