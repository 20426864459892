import { IconRefresh } from "@tabler/icons-react";
import { Button } from "components/button";

import { useTranslation } from "react-i18next";

import { Dialog } from "components/dialog/Dialog";
import { DialogTrigger } from "components/dialog/DialogTrigger";
import { DialogContent } from "components/dialog/DialogContent";
import { DialogTitle } from "components/dialog/DialogTitle";
import { DialogBody } from "components/dialog/DialogBody";
import { Radio } from "components/checkbox/Radio";
import { Text } from "../../../../common/components/atoms/typography/Text";
import { DialogActions } from "components/dialog/DialogActions";
import { useState } from "react";
import {
	useRunFullLoadDlmRawForCompanyDomainMutation,
	useRunFullLoadDlmRawForIntegrationMutation,
} from "src/common/redux/api/exopenApi";
import { Select } from "components/select/Select";
import {
	showErrorNotification,
	showSuccessNotification,
} from "components/notifications/events";

interface Props {
	companyDomainId: string;
	dataObjects: { label: string; value: string; system: string }[];
	integrationId?: number;
	disabled?: boolean;
	system?: string;
}

export const TriggerDlmRawDataRunModalButton = ({
	companyDomainId,
	dataObjects,
	integrationId,
	disabled,
	system,
}: Props) => {
	const { t } = useTranslation();

	const [open, setOpen] = useState(false);
	const [configValue, setConfigValue] = useState<"all" | "single">("all");
	const [dataObjectType, setDataObjectType] = useState<string | null>(null);

	const [runDlmLoadForCompanyDomain, { isLoading: isStartingDataLoad }] =
		useRunFullLoadDlmRawForCompanyDomainMutation();
	const [
		runDlmLoadForLegalEntity,
		{ isLoading: isStartingDataLoadForLegalEntity },
	] = useRunFullLoadDlmRawForIntegrationMutation();

	const systemDataObjects = system
		? dataObjects.filter(
				(dataObject) =>
					dataObject.system.toLowerCase() === system.toLowerCase(),
			)
		: dataObjects;

	return (
		<Dialog open={open}>
			<DialogTrigger asChild>
				<Button
					ariaLabel={t("Trigger datarun {{mart}}", { mart: "Raw" })}
					icon={<IconRefresh />}
					variant={integrationId ? "ghost" : "secondary"}
					disabled={disabled ?? false}
					onClick={() => setOpen(true)}
				>
					{integrationId ? "" : t("Trigger datarun {{mart}}", { mart: "Raw" })}
				</Button>
			</DialogTrigger>
			<DialogContent size="md">
				<DialogTitle>
					{integrationId
						? t("Start datarun for integration")
						: t("Start datarun for company domain")}
				</DialogTitle>
				<DialogBody>
					<label className="mb-5 flex items-center gap-2">
						<div className="shrink-0">
							<Radio
								checked={configValue === "all"}
								onChange={() => {
									setConfigValue("all");
									if (dataObjectType) setDataObjectType(null);
								}}
							/>
						</div>
						<div>
							<Text size="sm" color="text-grey-700" weight="medium">
								{t("All data objects")}
							</Text>
							<Text size="sm" color="text-grey-500" weight="regular">
								{integrationId
									? t(
											"A data load will be run for all data objects for the chosen integration.",
										)
									: t(
											"A data load will be run for all integrations and all data objects.",
										)}
							</Text>
						</div>
					</label>
					<label className="mb-5 flex items-center gap-2">
						<div className="shrink-0">
							<Radio
								checked={configValue === "single"}
								onChange={() => setConfigValue("single")}
							/>
						</div>
						<div>
							<Text size="sm" color="text-grey-700" weight="medium">
								{t("Single data object")}
							</Text>
							<Text size="sm" color="text-grey-500" weight="regular">
								{integrationId
									? t(
											"A data load will be run for the selected data object for the chosen integration.",
										)
									: t(
											"A data load will be run for all integrations for the selected data object.",
										)}
							</Text>
						</div>
					</label>
					{configValue === "single" && (
						<Select
							aria-labelledby={companyDomainId}
							placeholder={t("Select data object")}
							options={systemDataObjects}
							value={dataObjectType}
							onChange={(value) => {
								if (value) {
									setDataObjectType(value);
								}
							}}
							className="mb-4"
						/>
					)}
				</DialogBody>
				<DialogActions
					withCancelButton
					onCancel={() => {
						setConfigValue("all");
						setDataObjectType(null);
						setOpen(false);
					}}
				>
					<Button
						isLoading={isStartingDataLoad || isStartingDataLoadForLegalEntity}
						icon={<IconRefresh />}
						onClick={async () => {
							let promise;
							if (!integrationId) {
								promise = runDlmLoadForCompanyDomain({
									companyDomainId,
									dataObjectType: dataObjectType ?? undefined,
								});
							} else {
								promise = runDlmLoadForLegalEntity({
									companyDomainId,
									integrationId,
									dataObjectType: dataObjectType ?? undefined,
								});
							}
							const result = await promise;
							if ("error" in result) {
								showErrorNotification({
									message: t("Something failed..."),
								});
							} else {
								showSuccessNotification({
									message: t("Data load has been started"),
								});
								setOpen(false);
							}
						}}
					>
						{t("Trigger datarun {{mart}}", { mart: "Raw" })}
					</Button>
				</DialogActions>
			</DialogContent>
		</Dialog>
	);
};
