import { IconSchema } from "@tabler/icons-react";
import { Button } from "components/button";

import { useTranslation } from "react-i18next";

import { Dialog } from "components/dialog/Dialog";
import { DialogTrigger } from "components/dialog/DialogTrigger";
import { DialogContent } from "components/dialog/DialogContent";
import { DialogTitle } from "components/dialog/DialogTitle";
import { DialogBody } from "components/dialog/DialogBody";
import { DialogActions } from "components/dialog/DialogActions";
import { useState } from "react";
import { Input } from "components/input/Input";
import { Radio } from "components/checkbox/Radio";
import { Text } from "../../../../common/components/atoms/typography/Text";
import { useSetFinanceDestinationSchemaDlmMutation } from "src/common/redux/api/exopenApi";
import { Chip } from "components/chip/Chip";
import {
	showErrorNotification,
	showSuccessNotification,
} from "components/notifications/events";

interface Props {
	companyDomainId: string;
	currentDestinationSchema?: string | undefined;
	disabled?: boolean | undefined;
	onSetDestinationSchemaSuccess?: (() => void) | undefined;
}

enum DestinationSchemaOptions {
	DlmFinance = "DlmFinance",
	Finance = "Finance",
	Custom = "Custom",
}

export const ChangeDlmFinanceDestinationSchemaModalButton = ({
	companyDomainId,
	currentDestinationSchema,
	disabled,
	onSetDestinationSchemaSuccess,
}: Props) => {
	const { t } = useTranslation();

	const [open, setOpen] = useState(false);
	const [selectedRadio, setSelectedRadio] =
		useState<DestinationSchemaOptions>();
	const [destinationSchema, setDestinationSchema] = useState<string>();

	const [setDestinationSchemaDlm, { isLoading: isSettingDestinationSchema }] =
		useSetFinanceDestinationSchemaDlmMutation();

	if (currentDestinationSchema && destinationSchema === undefined) {
		setDestinationSchema(currentDestinationSchema);

		currentDestinationSchema === DestinationSchemaOptions.Finance ||
		currentDestinationSchema === DestinationSchemaOptions.DlmFinance
			? setSelectedRadio(DestinationSchemaOptions[currentDestinationSchema])
			: setSelectedRadio(DestinationSchemaOptions.Custom);
	}

	const currentDestinationSchemaIsCustom =
		currentDestinationSchema &&
		currentDestinationSchema !== DestinationSchemaOptions.Finance &&
		currentDestinationSchema !== DestinationSchemaOptions.DlmFinance;

	return (
		<Dialog open={open}>
			<DialogTrigger asChild>
				<Button
					ariaLabel={t("Change destination schema")}
					icon={<IconSchema />}
					disabled={disabled ?? false}
					onClick={() => setOpen(true)}
				>
					{t("Change destination schema")}
				</Button>
			</DialogTrigger>
			<DialogContent size="md">
				<DialogTitle>{t("Choose Desired Configuration")}</DialogTitle>
				<DialogBody>
					<label className="mb-5 flex items-center gap-2">
						<div className="shrink-0">
							<Radio
								checked={selectedRadio === DestinationSchemaOptions.DlmFinance}
								onChange={() => {
									setDestinationSchema(DestinationSchemaOptions.DlmFinance);
									setSelectedRadio(DestinationSchemaOptions.DlmFinance);
								}}
							/>
						</div>
						<Text size="sm" color="text-grey-700" weight="medium">
							{DestinationSchemaOptions.DlmFinance}
						</Text>
						{currentDestinationSchema ===
							DestinationSchemaOptions.DlmFinance && (
							<Chip size="small" color="primary">
								{t("CURRENT SCHEMA")}
							</Chip>
						)}
					</label>
					<label className="mb-5 flex items-center gap-2">
						<div className="shrink-0">
							<Radio
								checked={selectedRadio === DestinationSchemaOptions.Finance}
								onChange={() => {
									setDestinationSchema(DestinationSchemaOptions.Finance);
									setSelectedRadio(DestinationSchemaOptions.Finance);
								}}
							/>
						</div>
						<Text size="sm" color="text-grey-700" weight="medium">
							{DestinationSchemaOptions.Finance}
						</Text>
						{currentDestinationSchema === DestinationSchemaOptions.Finance && (
							<Chip size="small" color="primary">
								{t("CURRENT SCHEMA")}
							</Chip>
						)}
					</label>
					<label className="mb-5 flex items-center gap-2">
						<div className="shrink-0">
							<Radio
								checked={selectedRadio === DestinationSchemaOptions.Custom}
								onChange={() => {
									setSelectedRadio(DestinationSchemaOptions.Custom);

									currentDestinationSchemaIsCustom
										? setDestinationSchema(currentDestinationSchema)
										: setDestinationSchema("");
								}}
							/>
						</div>
						<Text size="sm" color="text-grey-700" weight="medium">
							{t("Custom")}
						</Text>
						{currentDestinationSchemaIsCustom && (
							<Chip size="small" color="primary">
								{t("CURRENT SCHEMA")}
							</Chip>
						)}
					</label>
					{selectedRadio === DestinationSchemaOptions.Custom && (
						<Input
							onChange={(event) => {
								setDestinationSchema(event.target.value);
							}}
							value={destinationSchema}
						/>
					)}
				</DialogBody>
				<DialogActions
					withCancelButton
					onCancel={() => {
						setDestinationSchema(undefined);
						setOpen(false);
					}}
				>
					<Button
						isLoading={isSettingDestinationSchema}
						icon={<IconSchema />}
						disabled={!destinationSchema}
						onClick={async () => {
							if (destinationSchema) {
								const result = await setDestinationSchemaDlm({
									companyDomainId,
									destinationSchema,
								});
								if ("error" in result) {
									showErrorNotification({
										message: t("Something failed..."),
									});
								} else {
									showSuccessNotification({
										message: t("Changes successfully implemented"),
									});
									if (onSetDestinationSchemaSuccess)
										onSetDestinationSchemaSuccess();

									destinationSchema == DestinationSchemaOptions.DlmFinance ||
									destinationSchema == DestinationSchemaOptions.Finance
										? setSelectedRadio(
												DestinationSchemaOptions[destinationSchema],
											)
										: setSelectedRadio(DestinationSchemaOptions.Custom);

									setOpen(false);
								}
							}
						}}
					>
						{t("Change destination schema")}
					</Button>
				</DialogActions>
			</DialogContent>
		</Dialog>
	);
};
