import { EntityHierarchyEditor } from "../components/EntityHierarchyEditor.js";
import {
	EntityHierarchyProvider,
	type Props as ProviderProps,
} from "../EntityHierarchyProvider.js";
import { useTranslation } from "react-i18next";
import { Chip } from "../../../common/components/atoms/chip/Chip.js";
import { useCurrentHierarchyMode } from "../hooks/useCurrentHierarchyMode.js";
import { useCurrentCompanyDomain } from "../../../common/company-domain/useCurrentCompanyDomain.js";
import { useMemo, useState } from "react";
import { Button } from "../../../common/components/atoms/button/Button.js";
import type {
	BaseQueryFn,
	FetchBaseQueryError,
	TypedUseMutationResult,
} from "@reduxjs/toolkit/dist/query/react";
import type { SerializedError } from "@reduxjs/toolkit";
import { showErrorNotification } from "../../../common/components/atoms/notifications/events.js";
import { AccountHierarchyGroupAttributesEditor } from "../components/AccountHierarchyGroupAttributesEditor.js";
import type {
	Account,
	AccountHierarchyGroupAttributes,
} from "src/common/service/nexus/endpoints/account/types.js";
import type {
	EntityHierarchy,
	EntityHierachyResponse,
	HierarchyType,
} from "src/common/service/nexus/utils/hierarchy.js";
import { LoadingState } from "components/loadingState/LoadingState.js";
import { MissingDataState } from "components/missingDataState/MissingDataState.js";
import { Dialog } from "components/dialog/Dialog.js";
import { DialogActions } from "components/dialog/DialogActions.js";
import { DialogTitle } from "components/dialog/DialogTitle.js";
import { DialogBody } from "components/dialog/DialogBody.js";
import { DialogContent } from "components/dialog/DialogContent.js";

type MutationResult =
	| { data: EntityHierachyResponse<Account, AccountHierarchyGroupAttributes> }
	| { error: FetchBaseQueryError | SerializedError };

type ContextProps = ProviderProps<Account, AccountHierarchyGroupAttributes>;

type MutationArgs = {
	companyDomainId: string;
	legalEntityId: string;
	type: HierarchyType;
};

type Props = Pick<
	ContextProps,
	| "useHierarchyQuery"
	| "createGroupMutation"
	| "updateGroupMutation"
	| "removeGroupMutation"
> & {
	useAutoQuery: ContextProps["useHierarchyQuery"];
	migrateHierarchyMutation: readonly [
		(args: MutationArgs) => Promise<MutationResult>,
		TypedUseMutationResult<
			EntityHierachyResponse<Account, AccountHierarchyGroupAttributes>,
			MutationArgs,
			BaseQueryFn
		>,
	];
};

export const FinancialStatementPage = ({
	useHierarchyQuery,
	useAutoQuery,
	createGroupMutation,
	updateGroupMutation,
	removeGroupMutation,
	migrateHierarchyMutation,
}: Props) => {
	const [migrateHierarchy, { isLoading: isMigrating }] =
		migrateHierarchyMutation;

	const { t } = useTranslation();
	const { hierarchyType, parentLegalEntityId } = useCurrentHierarchyMode();
	const { companyDomain } = useCurrentCompanyDomain();

	const [isMigrateModalOpen, setIsMigrateModalOpen] = useState(false);

	const { data, isError, isLoading } = useHierarchyQuery({
		companyDomainId: companyDomain.id,
		legalEntityId: parentLegalEntityId,
		type: hierarchyType,
	});

	const usedVisualizationCodes = useMemo(() => {
		const codes: Record<
			AccountHierarchyGroupAttributes["type"],
			Set<string>
		> = {
			Sum: new Set(),
			Group: new Set(),
		};

		if (!data) {
			return codes;
		}

		const getVisualizationCodesFromGroup = (
			group: EntityHierarchy<Account, AccountHierarchyGroupAttributes>,
		) => {
			if (group.attributes.visualizationCode) {
				codes[group.attributes.type].add(group.attributes.visualizationCode);
			}
			for (const child of group.children) {
				getVisualizationCodesFromGroup(child);
			}
		};

		for (const group of data.hierarchy) {
			getVisualizationCodesFromGroup(group);
		}

		return codes;
	}, [data]);

	if (isLoading) {
		return <LoadingState />;
	}

	if (isError || !data) {
		return <MissingDataState />;
	}

	const hasCustomHierarchy = data.hierarchy.length > 0;

	return (
		<>
			<Dialog
				open={isMigrateModalOpen}
				onClose={() => setIsMigrateModalOpen(false)}
			>
				<DialogContent>
					<DialogTitle>
						{t("Do you want to customize the account hierarchy?")}
					</DialogTitle>
					<DialogBody>
						<div className="text-sm">
							{t(
								"With a custom account hierarchy, any changes to your set of accounts needs to be mapped to the account hierarchy manually.",
							)}
						</div>
					</DialogBody>
					<DialogActions withCancelButton>
						<Button
							onClick={async () => {
								const result = await migrateHierarchy({
									companyDomainId: companyDomain.id,
									legalEntityId: parentLegalEntityId,
									type: hierarchyType,
								});

								if ("error" in result) {
									showErrorNotification({
										message: t("Something failed..."),
									});
								} else {
									setIsMigrateModalOpen(false);
								}
							}}
							isLoading={isMigrating}
						>
							{t("Customize Account Hierarchy")}
						</Button>
					</DialogActions>
				</DialogContent>
			</Dialog>
			<EntityHierarchyProvider
				labels={{
					entityName: t("Account"),
					entityNamePlural: t("Accounts"),
				}}
				getGroupLabel={({ name, attributes, children }) => {
					const visualizationCodeChip = attributes.visualizationCode ? (
						<Chip className="ml-4" size="small" color="primary">
							{attributes.visualizationCode}
						</Chip>
					) : null;

					if (attributes.type === "Sum") {
						return (
							<>
								{name}{" "}
								<Chip className="ml-4" size="small" color="purple">
									{t("Sum")}
								</Chip>{" "}
								{visualizationCodeChip}
							</>
						);
					}

					const numberOfChildren = children.length;

					return (
						<>
							{name}
							{numberOfChildren > 0 ? ` (${numberOfChildren})` : ""}
							{visualizationCodeChip}
						</>
					);
				}}
				useHierarchyQuery={
					hasCustomHierarchy ? useHierarchyQuery : useAutoQuery
				}
				createGroupMutation={createGroupMutation}
				updateGroupMutation={updateGroupMutation}
				removeGroupMutation={removeGroupMutation}
				getItemLabel={(item) => item.name}
				canHaveChildren={({ attributes }) => attributes.type === "Group"}
				canHaveItems={({ attributes }) => attributes.type === "Group"}
				canBeChildOf={({ attributes }) => attributes.type === "Group"}
				getDefaultGroupAttributes={(): AccountHierarchyGroupAttributes => ({
					type: "Group",
					visualizationCode: null,
				})}
				renderGroupAttributesUpdate={({
					attributes,
					onChange,
					attributeErrors,
				}) => {
					return (
						<AccountHierarchyGroupAttributesEditor
							attributes={attributes}
							disableGroupType
							onChange={onChange}
							attributeErrors={attributeErrors}
						/>
					);
				}}
				validateAttributes={(attributes) => {
					if (!attributes.visualizationCode) {
						return null;
					}

					if (
						attributes.type === "Sum" &&
						usedVisualizationCodes.Group.has(attributes.visualizationCode)
					) {
						return {
							visualizationCode: t(
								"This visualization code is already used by a group",
							),
						};
					}
					if (
						attributes.type === "Group" &&
						usedVisualizationCodes.Sum.has(attributes.visualizationCode)
					) {
						return {
							visualizationCode: t(
								"This visualization code is already used by a sum",
							),
						};
					}
					return null;
				}}
				renderGroupAttributesCreate={({
					attributes,
					onChange,
					parentNode,
					attributeErrors,
				}) => {
					return (
						<AccountHierarchyGroupAttributesEditor
							attributes={attributes}
							disableGroupType={parentNode !== null}
							onChange={onChange}
							attributeErrors={attributeErrors}
						/>
					);
				}}
				entityColumns={[
					{ key: "number", label: t("Account number") },
					{ key: "name", label: t("Name") },
				]}
				type={hierarchyType}
			>
				<EntityHierarchyEditor
					isEditable={hasCustomHierarchy}
					onEdit={() => setIsMigrateModalOpen(true)}
				/>
			</EntityHierarchyProvider>
		</>
	);
};
