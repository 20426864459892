import { type SubmitHandler, useForm } from "react-hook-form";
import { Label } from "../../common/components/atoms/label/Label";
import {
	showErrorNotification,
	showSuccessNotification,
} from "../../common/components/atoms/notifications/events";
import { useCreateFakePumpMutation } from "../../common/redux/api/exopenApi";
import { Button } from "../../common/components/atoms/button/Button";
import { Input } from "../../common/components/atoms/input/Input";
import { useId } from "react";
import { Dialog } from "components/dialog/Dialog.tsx";
import { DialogTitle } from "components/dialog/DialogTitle.tsx";
import { DialogBody } from "components/dialog/DialogBody.tsx";
import { DialogActions } from "components/dialog/DialogActions.tsx";
import { DialogContent } from "components/dialog/DialogContent.tsx";

interface Inputs {
	legalEntityId: string;
	name: string;
}

interface FakePumpProps {
	pump: string | null;
	onClose: () => void;
	companyId: string;
}

export const FakePumpModal = ({ pump, onClose, companyId }: FakePumpProps) => {
	const {
		register,
		handleSubmit,
		formState: { isSubmitting },
		reset,
	} = useForm<Inputs>({ defaultValues: { legalEntityId: "", name: "" } });

	const [createFakePump] = useCreateFakePumpMutation();

	const submitHandler: SubmitHandler<Inputs> = async (data) => {
		const result = await createFakePump({
			companyId,
			pumpType: pump!,
			legalEntityId: data.legalEntityId,
			name: data.name,
		});
		if ("error" in result) {
			showErrorNotification({ message: "Något gick fel" });
		} else {
			showSuccessNotification({ message: "Fejkpumpen skapades" });
			reset({ name: "", legalEntityId: "" });
			onClose();
		}
	};

	const formId = useId();

	return (
		<Dialog open={pump !== null} onClose={onClose}>
			<DialogContent>
				<DialogTitle>Ny fejkpump</DialogTitle>
				<DialogBody>
					<form id={formId} onSubmit={handleSubmit(submitHandler)}>
						<Label htmlFor="fake-pump-legal-entity-id">Legal enhets-ID</Label>
						<Input
							id="fake-pump-legal-entity-id"
							{...register("legalEntityId")}
							containerProps={{ className: "mb-4" }}
						/>

						<Label htmlFor="fake-pump-name">Namn</Label>
						<Input id="fake-pump-name" {...register("name")} />
					</form>
				</DialogBody>
				<DialogActions withCancelButton>
					<Button
						isLoading={isSubmitting}
						disabled={isSubmitting}
						variant="primary"
						form={formId}
						type="submit"
					>
						Skapa fejkpump
					</Button>
				</DialogActions>
			</DialogContent>
		</Dialog>
	);
};
